import { default as breachesYJ86UMh8v8Meta } from "/opt/buildhome/repo/app/hub/pages/breaches.vue?macro=true";
import { default as _91simulationId_93oUQtFKcLiBMeta } from "/opt/buildhome/repo/app/hub/pages/click/[employeeId]/[simulationId].vue?macro=true";
import { default as indexCbHdQc4RUoMeta } from "/opt/buildhome/repo/app/hub/pages/courses/[courseId]/index.vue?macro=true";
import { default as indexWBBNm4pyHoMeta } from "/opt/buildhome/repo/app/hub/pages/courses/index.vue?macro=true";
import { default as coursesSjrY79gUttMeta } from "/opt/buildhome/repo/app/hub/pages/courses.vue?macro=true";
import { default as indexowk0CCdOWpMeta } from "/opt/buildhome/repo/app/hub/pages/index.vue?macro=true";
import { default as indexMqRwGpXRr6Meta } from "/opt/buildhome/repo/app/hub/pages/login/index.vue?macro=true";
import { default as linkTAsnSVBTSsMeta } from "/opt/buildhome/repo/app/hub/pages/login/link.vue?macro=true";
import { default as logoutLCfKJY27ZgMeta } from "/opt/buildhome/repo/app/hub/pages/logout.vue?macro=true";
import { default as indexMAaJg3O5VaMeta } from "/opt/buildhome/repo/app/hub/pages/onboarding/index.vue?macro=true";
import { default as index4XeLlRGswMMeta } from "/opt/buildhome/repo/app/hub/pages/quiz/index.vue?macro=true";
import { default as simulationsi1GvVWvMOVMeta } from "/opt/buildhome/repo/app/hub/pages/simulations.vue?macro=true";
import { default as _91provider_93m1qV8xCugNMeta } from "/opt/buildhome/repo/app/hub/pages/sso/[provider].vue?macro=true";
import { default as indexWoc3A97DhdMeta } from "/opt/buildhome/repo/app/hub/pages/videos/[category]/index.vue?macro=true";
import { default as _91id_93wJmZn1Q12TMeta } from "/opt/buildhome/repo/app/hub/pages/videos/[category]/video/[id].vue?macro=true";
import { default as indexS8l8FeIwsUMeta } from "/opt/buildhome/repo/app/hub/pages/videos/index.vue?macro=true";
import { default as videoslApxR8zF0ZMeta } from "/opt/buildhome/repo/app/hub/pages/videos.vue?macro=true";
export default [
  {
    name: "breaches",
    path: "/breaches",
    component: () => import("/opt/buildhome/repo/app/hub/pages/breaches.vue")
  },
  {
    name: "click-employeeId-simulationId",
    path: "/click/:employeeId()/:simulationId()",
    meta: _91simulationId_93oUQtFKcLiBMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/click/[employeeId]/[simulationId].vue")
  },
  {
    name: coursesSjrY79gUttMeta?.name,
    path: "/courses",
    component: () => import("/opt/buildhome/repo/app/hub/pages/courses.vue"),
    children: [
  {
    name: "courses-courseId",
    path: ":courseId()",
    meta: indexCbHdQc4RUoMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/courses/[courseId]/index.vue")
  },
  {
    name: "courses",
    path: "",
    component: () => import("/opt/buildhome/repo/app/hub/pages/courses/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/app/hub/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexMqRwGpXRr6Meta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/login/index.vue")
  },
  {
    name: "login-link",
    path: "/login/link",
    meta: linkTAsnSVBTSsMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/login/link.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutLCfKJY27ZgMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/logout.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexMAaJg3O5VaMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/onboarding/index.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: index4XeLlRGswMMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/quiz/index.vue")
  },
  {
    name: "simulations",
    path: "/simulations",
    component: () => import("/opt/buildhome/repo/app/hub/pages/simulations.vue")
  },
  {
    name: "sso-provider",
    path: "/sso/:provider()",
    meta: _91provider_93m1qV8xCugNMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/sso/[provider].vue")
  },
  {
    name: videoslApxR8zF0ZMeta?.name,
    path: "/videos",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos.vue"),
    children: [
  {
    name: "videos-category",
    path: ":category()",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos/[category]/index.vue")
  },
  {
    name: "videos-category-video-id",
    path: ":category()/video/:id()",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos/[category]/video/[id].vue")
  },
  {
    name: "videos",
    path: "",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos/index.vue")
  }
]
  }
]